import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import ReceiptsViewer from "../../../expenses/components/receipts/ReceiptsViewer";
import MapContent from "../../components/MapContent";

const RightContent = ({
  directionsResponse,
  calculating,
  receipts = [],
  onUploadFiles = () => {},
  onRemoveReceipt = () => {},
}) => {
  const [tab, setTab] = useState(0);
  return (
    <Box flex={1} height={"100%"} display={"flex"} flexDirection={"column"}>
      <Box borderBottom={1} borderColor={"divider"}>
        <Tabs
          value={tab}
          onChange={(e, v) => setTab(v)}
          sx={{
            minHeight: 35,
            "& .MuiTabs-root": {
              minHeight: 35,
              position: "relative",
            },
            "& .MuiTab-root": {
              minHeight: 35,
            },
          }}
        >
          <Tab value={0} label="Mapa" />
          <Tab
            value={1}
            label={`Comprovantes${
              receipts.length ? ` (${receipts.length})` : ""
            }`}
          />
        </Tabs>
      </Box>
      {tab === 0 && (
        <MapContent
          directionsResponse={directionsResponse}
          loadingMap={calculating}
        />
      )}
      {tab === 1 && (
        <ReceiptsViewer
          receipts={receipts}
          onUploadFiles={onUploadFiles}
          onRemove={onRemoveReceipt}
          isEditable
          isNewExpense
        />
      )}
    </Box>
  );
};

export default RightContent;
