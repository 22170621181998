import { CategoryOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import AvatarType from "../../../../components/avatar/AvatarType";
import Baseline from "../../../../components/form/Baseline";
import {
  getCategories,
  selectCategoriesByRole,
} from "../../../../store/features/configs/categoriesSlice";
import SelectorBox from "../SelectorBox";

function Category({
  value,
  readOnly,
  onChange = () => {},
  clearError = () => {},
  size,
  variant = "filled",
  inputRef,
  role = "personal",
  isEditable = true,
  disabled,
  margin = "dense",
  required,
  error = false,
  helperText,
  groupId,
  placeholder = "Adicionar categoria",
}) {
  const dispatch = useDispatch();

  const {
    data,
    status,
    error: sliceError,
  } = useSelector((state) => selectCategoriesByRole(state, role));

  const options = useMemo(() => {
    return data?.filter((item) =>
      item?.groups ? item?.groups?.includes(groupId) : true
    );
  }, [groupId, data]);

  const loading = status === "loading";

  const onOpen = () => {
    if (status === "idle") {
      dispatch(getCategories(role));
    }
  };

  useEffect(() => {
    if (isEditable && value?.groups) {
      if (!value?.groups?.includes(groupId)) {
        onChange("");
      }
    }
  }, [groupId]);

  if (!isEditable) {
    return (
      <Baseline
        Icon={CategoryOutlined}
        value={value?.name}
        label={"Categoria"}
      />
    );
  } else {
    return (
      <SelectorBox Icon={CategoryOutlined} active={Boolean(value)}>
        <Autocomplete
          size={size || "small"}
          autoHighlight
          fullWidth
          openOnFocus
          disabled={disabled}
          readOnly={readOnly}
          options={options || []}
          loading={loading}
          value={value || null}
          onOpen={onOpen}
          onChange={(e, v) => {
            clearError("category");
            onChange(v);
          }}
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          getOptionLabel={(option) => option?.name}
          renderOption={(props, option, { selected }) => {
            return (
              <MenuItem
                {...props}
                key={option?._id}
                selected={selected}
                sx={{ height: 45 }}
              >
                <AvatarType icon={option?.icon} size={30} sx={{ ml: -1 }} />
                <Typography noWrap variant="inherit" ml={1}>
                  {option?.name}
                </Typography>
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="category"
              margin={margin}
              inputRef={inputRef}
              required={required}
              error={error}
              helperText={helperText}
              multiline
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null}
                    {!disabled && params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              fullWidth
              variant={variant}
              placeholder={placeholder}
            />
          )}
        />
      </SelectorBox>
    );
  }
}

export default memo(Category);
