import { Box, keyframes } from "@mui/material";
import React, { memo } from "react";

// Definição da animação de pulsação
const ripple = keyframes`
  0% { transform: scale(0.8); opacity: 1; }
  100% { transform: scale(1.7); opacity: 0; }
`;

const BlinkingDot = ({ color = "primary" }) => {
  return (
    <Box
      sx={{
        width: 10,
        height: 10,
        bgcolor: (t) => t.palette[color]?.main,
        borderRadius: "50%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: 1,
        "&::after": {
          content: "''",
          position: "absolute",
          top: -1,
          left: -1,
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          animation: `${ripple} 1.2s infinite ease-in-out`,
          border: (t) => `1px solid ${t.palette[color]?.main}`,
        },
      }}
    />
  );
};

export default memo(BlinkingDot);
