import { PersonPin } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Baseline from "../../../../components/form/Baseline";
import { selectAccountCurrency } from "../../../../store/features/accountSlice";
import {
  getRoutePolicies,
  selectRoutePoliciesByRole,
} from "../../../../store/features/configs/routePoliciesSlice";
import formatAmount from "../../../../utils/formatAmount";
import getRateByDate from "../../../../utils/getRateByDate";
import { curr } from "../../../../utils/more/currency_country";
import SelectorBox from "../SelectorBox";

function RoutePoliciesInput({
  value,
  readOnly,
  onChange = () => {},
  size,
  variant = "filled",
  inputRef,
  role = "personal",
  isEditable = true,
  margin = "dense",
  date = new Date(),
}) {
  const dispatch = useDispatch();

  const {
    data,
    status,
    error: sliceError,
  } = useSelector((state) => selectRoutePoliciesByRole(state, role));

  const currency = useSelector(selectAccountCurrency);
  const loading = status === "loading";

  const options = useMemo(() => {
    return data?.map((item) => ({
      ...item,
      currentRate: getRateByDate(item?.history || [], new Date(date)),
    }));
  }, [data, date]);

  const onOpen = () => {
    dispatch(getRoutePolicies(role));
  };

  if (!isEditable) {
    return (
      <Baseline
        Icon={PersonPin}
        label={"Taxa"}
        value={
          value?.currentRate
            ? `${curr(currency)} ${formatAmount(value?.currentRate)} - ${
                value?.name
              }`
            : ""
        }
        emptyLabel={"Não informado"}
      />
    );
  } else {
    return (
      <SelectorBox Icon={PersonPin} active={Boolean(value)}>
        <Autocomplete
          size={size || "small"}
          autoHighlight
          fullWidth
          openOnFocus
          readOnly={readOnly}
          options={options || []}
          loading={loading}
          value={value || null}
          onOpen={onOpen}
          onChange={(e, v) => {
            onChange(v);
          }}
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          getOptionLabel={(option) =>
            `${curr(currency)} ${formatAmount(option?.currentRate)} - ${
              option?.name
            }`
          }
          renderOption={(props, option, { selected }) => {
            return (
              <MenuItem
                {...props}
                key={option?._id}
                selected={selected}
                sx={{ height: 70 }}
              >
                <Box>
                  <Typography fontWeight={"600"} variant="inherit">
                    {option?.name}
                  </Typography>
                  <Typography color={"text.secondary"} variant="body2">
                    Taxa:{" "}
                    <Typography
                      fontWeight={"600"}
                      color="primary"
                      component={"span"}
                      variant="inherit"
                    >
                      {curr(currency)} {formatAmount(option?.currentRate)}
                    </Typography>
                  </Typography>
                </Box>
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="routePolicy"
              margin={margin}
              inputRef={inputRef}
              multiline
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              fullWidth
              variant={variant}
              placeholder={"Selecionar taxa"}
            />
          )}
        />
      </SelectorBox>
    );
  }
}

export default memo(RoutePoliciesInput);
