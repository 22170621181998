import { CheckCircle, ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  Collapse,
  darken,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import BlinkingDot from "../../../../components/BlinkingDot";
import UserListItem from "../../../../components/USERS/UserListItem";
import { getUsernameInitials } from "../../../../utils/formaters";
import { getProfilePicture } from "../../../../utils/functions/profile";

const StepApproverItem = memo(({ approver = {} }) => {
  const { status } = approver;

  return (
    <UserListItem
      dense
      disableGutters
      clickable={false}
      user={approver}
      rightContent={
        status === "approved" ? (
          <Chip
            icon={<CheckCircle />}
            variant="outlined"
            color="success"
            size="small"
            label="Aprovou"
            sx={{ fontWeight: "600" }}
          />
        ) : (
          status === "pending" && (
            <Tooltip title="Aguardando aprovação...">
              <div className="typing">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Tooltip>
          )
        )
      }
    />
  );
});

const StepApproversInfo = ({ currentStep, stepApprovers = [], loading }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Box
      mb={2}
      borderRadius={5}
      bgcolor={(t) => darken(t.palette.elevation1.main, 0.02)}
    >
      <Box
        borderRadius={5}
        onClick={() => setExpanded(!expanded)}
        sx={{
          cursor: "pointer",
          ":hover": {
            bgcolor: "action.hover",
          },
        }}
        p={2}
        py={1}
        display={"flex"}
        alignItems={"center"}
      >
        <BlinkingDot />
        <Typography ml={2} noWrap fontSize={".95rem"} fontWeight={"600"}>
          Aguardando aprovadores
        </Typography>
        <Box flex={1} />
        {!expanded && (
          <AvatarGroup
            max={3}
            total={stepApprovers?.length}
            sx={{
              alignItems: "flex-start",
              ".MuiAvatar-root": {
                width: 20,
                height: 20,
                fontSize: ".7rem",
                fontWeight: 700,
              },
            }}
          >
            {stepApprovers?.map((user) => (
              <Avatar key={user?._id} src={getProfilePicture(user?._id)}>
                {getUsernameInitials(user?.name)}
              </Avatar>
            ))}
          </AvatarGroup>
        )}

        <IconButton
          color="inherit"
          sx={{ m: -1 }}
          disableTouchRipple
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Collapse timeout={100} unmountOnExit in={expanded}>
        <Stack p={2} pt={1} direction={"column"}>
          {loading
            ? stepApprovers?.map((_, index) => (
                <Skeleton key={index.toString()} height={52} />
              ))
            : stepApprovers?.map((user) => (
                <StepApproverItem key={user?._id} approver={user || {}} />
              ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default memo(StepApproversInfo);
