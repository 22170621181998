import { Groups2Outlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Baseline from "../../../../components/form/Baseline";
import { selectAccountGroups } from "../../../../store/features/accountSlice";
import {
  getGroups,
  selectGroupsByRole,
} from "../../../../store/features/configs/groupsSlice";
import SelectorBox from "../SelectorBox";

function GroupInput({
  value,
  readOnly,
  onChange = () => {},
  size,
  variant = "filled",
  inputRef,
  role = "personal",
  isEditable = true,
  disabled,
  margin = "dense",
  isAccount,
  enableDefaultValue,
  placeholder = "Grupo",
}) {
  const dispatch = useDispatch();

  const accountGroups = useSelector(selectAccountGroups);

  const {
    data,
    status,
    error: sliceError,
  } = useSelector((state) => selectGroupsByRole(state, role));

  const loading = status === "loading";

  const onOpen = () => {
    if (status === "idle" && !isAccount) {
      dispatch(getGroups(role));
    }
  };

  useEffect(() => {
    if (enableDefaultValue && isAccount && accountGroups[0] && !value) {
      onChange(accountGroups[0] || null);
    }
  }, [accountGroups]);

  if (!isEditable) {
    return (
      <Baseline Icon={Groups2Outlined} label={"Grupo"} value={value?.name} />
    );
  } else {
    return (
      <SelectorBox Icon={Groups2Outlined} active={Boolean(value)}>
        <Autocomplete
          size={size || "small"}
          autoHighlight
          fullWidth
          openOnFocus
          readOnly={readOnly}
          options={(isAccount ? accountGroups : data) || []}
          loading={loading}
          value={value || null}
          onOpen={onOpen}
          onChange={(e, v) => {
            onChange(v);
          }}
          disableClearable
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          getOptionLabel={(option) => option?.name}
          renderOption={(props, option, { selected }) => {
            return (
              <MenuItem {...props} key={option?._id} selected={selected}>
                <Box flex={1}>
                  <Typography fontWeight={"600"} noWrap variant="inherit">
                    {option?.name}
                  </Typography>
                  <Typography noWrap variant="body2" color="textSecondary">
                    {option?.org?.name}
                  </Typography>
                </Box>
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="group_id"
              margin={margin}
              inputRef={inputRef}
              multiline
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null}
                    {!disabled && params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              fullWidth
              variant={variant}
              placeholder={placeholder}
            />
          )}
        />
      </SelectorBox>
    );
  }
}

export default memo(GroupInput);
