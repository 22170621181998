import React, { useEffect, useState } from "react";
import LoadingSection from "../components/loading/LoadingSection";

const Delayed = ({
  children,
  disableLoadingComponent = false,
  waitBeforeShow = 150,
  hideOnChangeProp,
  disabled,
}) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (disabled) {
      setIsShown(true);
      return;
    }

    if (hideOnChangeProp && !disabled) {
      setIsShown(false);
    }
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow, hideOnChangeProp]);

  return disabled || isShown ? (
    children
  ) : disableLoadingComponent ? (
    <></>
  ) : (
    <LoadingSection thickness={5} size={27} />
  );
};

export default Delayed;
