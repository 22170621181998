import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { closeError } from "../../store/features/base/errorBaseSlice";

function ModalErrorBase(props) {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.errorBase.open);

  const errorBase = useSelector((state) => state.errorBase);
  const { title, subtitle, error, content, config } = errorBase || {};

  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (!open) {
      setShowMore(false);
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      transitionDuration={300}
      slotProps={{
        paper: {
          sx: { maxWidth: 480, borderRadius: 6, bgcolor: "elevation1.main" },
        },
      }}
    >
      <IconButton
        onClick={() => dispatch(closeError())}
        color="inherit"
        size="small"
        sx={{ position: "absolute", top: 5, right: 5, p: 1.5 }}
      >
        <Close />
      </IconButton>
      {open && (
        <>
          <DialogContent>
            <Stack alignItems={"center"}>
              <Lottie
                options={{
                  animationData: require("../../assets/lotties/person-error.json"),
                }}
                isClickToPauseDisabled
                style={{
                  marginTop: -70,
                  marginBottom: -40,
                  width: 250,
                  zIndex: 0,
                }}
              />
              <Typography
                zIndex={1}
                gutterBottom
                textAlign={"center"}
                fontSize={"1.5rem"}
                fontWeight={600}
              >
                {error?.response?.data?.title ||
                  title ||
                  "Ops, algo deu errado"}
              </Typography>
              <Stack alignItems={"center"} sx={{ textAlign: "center" }}>
                <Box
                  component={"div"}
                  textAlign={"center"}
                  fontSize={".95rem"}
                  color={"text.primary"}
                  sx={{ wordBreak: "break-word", opacity: 0.8 }}
                  fontWeight={"500"}
                  dangerouslySetInnerHTML={{
                    __html:
                      error?.response?.data?.message?.toString() ||
                      error?.response?.data?.toString() ||
                      subtitle ||
                      "",
                  }}
                />

                {process.env.REACT_APP_ENV === "development" && (
                  <>
                    <Divider sx={{ mt: 3, width: 100 }} />
                    <br />
                    <Typography component={"span"} fontSize={"0.8rem"}>
                      Código do erro:{" "}
                      <Typography
                        component={"span"}
                        variant="inherit"
                        fontWeight={600}
                      >
                        {error?.response?.data?.code ||
                          error?.code ||
                          "NO_CODE"}
                      </Typography>
                    </Typography>
                  </>
                )}

                {process.env.REACT_APP_ENV === "development" &&
                  error?.response?.data?.details && (
                    <Box>
                      <Button
                        onClick={() => setShowMore(!showMore)}
                        size="small"
                      >
                        Mais detalhes
                      </Button>
                      <Collapse in={showMore}>
                        <Box my={1} p={1} bgcolor={"#FFF"} borderRadius={3}>
                          <Typography
                            textAlign={"center"}
                            variant="body2"
                            fontWeight={"500"}
                          >
                            {error?.response?.data?.details}
                          </Typography>
                        </Box>
                      </Collapse>
                    </Box>
                  )}

                <Button
                  disableRipple
                  sx={{
                    transition: "none",
                    my: 4,
                    height: 50,
                    px: 5,
                    fontSize: "17px",
                  }}
                  variant="outlined"
                  onClick={() => dispatch(closeError())}
                >
                  Fechar
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export default ModalErrorBase;
