import { AutoAwesome, Close } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { memo } from "react";

const ModalHeader = ({
  onClose = () => {},
  onScan = () => {},
  scanning,
  hasReceipt,
}) => {
  return (
    <Stack
      px={3}
      pr={2}
      direction={"row"}
      alignItems={"center"}
      minHeight={51}
      maxHeight={51}
      gap={1}
    >
      <IconButton
        disableTouchRipple
        onClick={onClose}
        sx={{ m: -1, mr: 1 }}
        color="inherit"
      >
        <Close />
      </IconButton>
      <Typography fontSize={"1.2rem"} fontWeight={"500"}>
        Criar despesa
      </Typography>
      <Box flex={1} />
      {hasReceipt && (
        <Button
          disabled={scanning}
          onClick={onScan}
          startIcon={<AutoAwesome />}
          sx={{ bgcolor: "elevation1.main", height: 30 }}
        >
          Preencher despesa
        </Button>
      )}
    </Stack>
  );
};

export default memo(ModalHeader);
