import {
  AccessTime,
  BusinessOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { Box, Button, Collapse, Divider, Stack } from "@mui/material";
import { isValid } from "date-fns";
import React, { memo, useState } from "react";
import Baseline from "../../../../components/form/Baseline";
import { formatMoment } from "../../../../utils/more/date_functions";

const MoreInfo = ({
  isEditable,
  hasPermissionToEdit,
  org = {},
  group = {},
  createdAt,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      {!isEditable && !hasPermissionToEdit && (
        <Divider sx={{ my: -1, mt: 1 }} />
      )}
      <Box>
        <Button
          disableRipple
          onClick={() => setExpanded(!expanded)}
          startIcon={expanded ? <ExpandLess /> : <ExpandMore />}
        >
          {expanded ? "Mostrar menos" : "Mostrar mais"}
        </Button>
      </Box>
      <Collapse timeout={150} in={expanded}>
        <Stack gap={2}>
          {Boolean(createdAt) && isValid(new Date(createdAt)) && (
            <Baseline
              Icon={AccessTime}
              label={"Data de criação"}
              value={formatMoment(createdAt)}
              emptyLabel={""}
            />
          )}
          <Stack direction={"row"} gap={1}>
            <Baseline
              sx={{ flex: 1 }}
              Icon={BusinessOutlined}
              label={"Organização"}
              value={org?.name}
              emptyLabel={"Sem organização"}
            />
            <Baseline
              sx={{ flex: 1 }}
              label={"Empresa / Filial / Centro"}
              value={`${org?.code || "-"} / ${org?.branch || "-"} / ${
                org?.plant || "-"
              }`}
              emptyLabel={"--"}
            />
          </Stack>

          <Stack direction={"row"} gap={1}>
            <Baseline
              sx={{ flex: 1 }}
              label={"Grupo"}
              Icon={() => {}}
              value={group?.name}
              emptyLabel={"Sem grupo"}
            />
            <Baseline
              sx={{ flex: 1 }}
              label={"Centro de custo"}
              value={group?.costcenter}
              emptyLabel={"Sem centro de custo"}
            />
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
};

export default memo(MoreInfo);
