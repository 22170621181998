import { NotesOutlined } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React, { memo } from "react";
import Baseline from "../../../../components/form/Baseline";
import SelectorBox from "../SelectorBox";

function ObsInput({
  value = "",
  size,
  readOnly,
  onChange,
  variant = "filled",
  isEditable = true,
  margin = "dense",
  required,
  helperText,
  error,
  clearError = () => {},
  placeholder = "Adicionar descrição",
}) {
  if (!isEditable) {
    return (
      <Baseline
        Icon={NotesOutlined}
        value={value}
        emptyLabel={"Sem descrição"}
      />
    );
  } else {
    return (
      <SelectorBox
        iconSx={{ mt: 2 }}
        sx={{ alignItems: "flex-start" }}
        Icon={NotesOutlined}
        active={Boolean(value)}
      >
        <TextField
          placeholder={placeholder}
          fullWidth
          readOnly={readOnly}
          variant={variant}
          name="notes"
          size={"small"}
          margin={margin}
          required={required}
          value={value}
          onChange={(e) => {
            clearError("notes");
            onChange(e.target.value);
          }}
          multiline
          error={error}
          helperText={helperText}
        />
      </SelectorBox>
    );
  }
}

export default memo(ObsInput);
