import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalExpensesScanner: {
    open: false,
    payload: null,
  },
  modalNewExpense: {
    open: false,
    payload: null,
  },
  modalExpenseView: {
    open: false,
    payload: null,
  },
  modalNewRoute: {
    open: false,
    payload: null,
  },
  modalCreateReport: {
    open: false,
    payload: null,
  },
  modalNewAdvance: {
    open: false,
    payload: null,
  },
  pdfViewer: {
    open: false,
    payload: null,
  },

  //
  modalExpensesFailedResume: {
    open: false,
    payload: null,
  },

  // discounts
  discountExpenseModal: {
    open: false,
    payload: null,
  },
};

const modalsSlice = createSlice({
  name: "modalsBase",
  initialState,
  reducers: {
    openElement: (state, action) => {
      const { name, payload } = action.payload;
      state[name].open = true;
      state[name].payload = payload || null;
    },
    closeElement: (state, action) => {
      state[action.payload].open = false;
      state[action.payload].payload = null;
    },
  },
});

export const { openElement, closeElement } = modalsSlice.actions;

export const selectModalInfo = (state, modalName) => {
  return (
    state.modalsBase[modalName] || {
      open: false,
      payload: null,
    }
  );
};
export const selectModalPayload = createSelector(
  [(state) => state.modalsBase, (state, modalName) => modalName],
  (modalsBase, modalName) => {
    return modalsBase[modalName]?.payload || {};
  }
);
export const selectModalIsOpened = (state, modalName) => {
  return Boolean(state?.modalsBase[modalName]?.open);
};

export default modalsSlice.reducer;
