import {
  DeleteOutlineOutlined,
  MoreVert,
  Percent,
  Rule,
  SendOutlined,
} from "@mui/icons-material";
import { IconButton, MenuItem, Popover } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import DiscountExpenseWrapper from "../../../../../../actions/expenses/DiscountExpenseWrapper";
import EditExpensesStatusWrapper from "../../../../../../actions/expenses/EditExpensesStatusWrapper";
import RemoveExpensesWrapper from "../../../../../../actions/expenses/RemoveExpensesWrapper";
import IsNewChip from "../../../../../../components/chip/IsNewChip";
import AcctValuesModal from "./AcctValuesModal";
import CopyModel from "./CopyModel";
import SendConfirmationWrapper from "./SendConfirmationWrapper";

const MoreButton = ({
  isEditable,
  expenseId,
  role,
  originalValuesRef,
  onSendToApproval,
  disabled,
  onClose,
  onRefresh = () => {},
}) => {
  const [menu, setMenu] = useState(null);
  const closeMenu = useCallback(() => setMenu(null), []);

  const renderDiscountAction = useCallback(
    ({ openModal }) => (
      <MenuItem
        sx={{ gap: 2 }}
        onClick={(e) => {
          openModal({
            target: e.target,
            payload: {
              expenseId,
              originalAmount: originalValuesRef?.current?.originalAmount || {},
            },
          });
          closeMenu();
        }}
      >
        <Percent color="action" />
        Aplicar desconto
        <IsNewChip />
        <span></span>
      </MenuItem>
    ),
    [expenseId, originalValuesRef]
  );

  const renderEditStatusAction = useCallback(
    ({ openModal }) => (
      <MenuItem disabled={disabled} onClick={() => openModal([expenseId])}>
        <Rule sx={{ mr: 2 }} color="action" />
        Alterar status
      </MenuItem>
    ),
    [disabled, expenseId]
  );

  return (
    <>
      <IconButton onClick={(e) => setMenu(e.target)}>
        <MoreVert />
      </IconButton>
      <Popover
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={closeMenu}
        transitionDuration={0}
        keepMounted
        slotProps={{
          paper: {
            sx: {
              borderRadius: 1.5,
              py: 1,
              boxShadow: 5,
            },
          },
        }}
        sx={{
          "& .MuiMenuItem-root": {
            height: 42,
            fontSize: ".925rem",
            fontWeight: "600",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1.3rem",
          },
        }}
      >
        {isEditable && role === "personal" && (
          <SendConfirmationWrapper
            expensesIds={[expenseId]}
            onSendToApproval={onSendToApproval}
            renderComponent={({ openModal }) => (
              <MenuItem disabled={disabled} color="primary" onClick={openModal}>
                <SendOutlined sx={{ mr: 2 }} color="action" />
                Enviar para aprovação
              </MenuItem>
            )}
          />
        )}
        <CopyModel
          disabled={disabled}
          originalValuesRef={originalValuesRef}
          closeMenu={closeMenu}
        />
        {["financial", "director"].includes(role) && (
          <EditExpensesStatusWrapper
            role={role}
            onSuccess={onRefresh}
            renderComponent={renderEditStatusAction}
          />
        )}
        {["financial", "director"].includes(role) && (
          <AcctValuesModal
            entityId={expenseId}
            originalValuesRef={originalValuesRef}
            type="expenses"
          />
        )}
        {["financial", "director"].includes(role) && !isEditable && (
          <>
            <DiscountExpenseWrapper
              role={role}
              onSuccess={onRefresh}
              renderComponent={renderDiscountAction}
            />
          </>
        )}
        {(isEditable || ["financial", "director"].includes(role)) && (
          <RemoveExpensesWrapper
            onClose={onClose}
            role={role}
            renderComponent={({ openModal }) => (
              <MenuItem
                disabled={disabled}
                onClick={() => openModal([expenseId])}
              >
                <DeleteOutlineOutlined sx={{ mr: 2 }} color="action" />
                Excluir
              </MenuItem>
            )}
          />
        )}
      </Popover>
    </>
  );
};

export default memo(MoreButton);
