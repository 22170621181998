import { Box, useTheme } from "@mui/material";
import React, { memo } from "react";

const NotepayLogo = ({ sx }) => {
  const {
    palette: { mode },
  } = useTheme();
  return (
    <Box
      width={35}
      height={35}
      borderRadius={100}
      sx={{
        background: 'url("https://cdn.notepay.com.br/logo-icon-1.png")',
        backgroundPosition: "center",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        ...sx,
      }}
    ></Box>
  );
};

export default memo(NotepayLogo);
