import { DirectionsCar } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Baseline from "../../../../components/form/Baseline";
import {
  getVehicles,
  selectVehiclesByRole,
} from "../../../../store/features/configs/vehiclesSlice";
import SelectorBox from "../SelectorBox";

function VehiclesInput({
  value,
  readOnly,
  onChange = () => {},
  size,
  variant = "filled",
  inputRef,
  role = "personal",
  isEditable = true,
  margin = "dense",
}) {
  const dispatch = useDispatch();

  const {
    data,
    status,
    error: sliceError,
  } = useSelector((state) => selectVehiclesByRole(state, role));

  const loading = status === "loading";

  const onOpen = () => {
    if (status === "idle") {
      dispatch(getVehicles(role));
    }
  };

  if (!isEditable) {
    return (
      <Baseline
        Icon={DirectionsCar}
        value={value?.name}
        emptyLabel={"Veículo não informado"}
      />
    );
  } else {
    return (
      <SelectorBox Icon={DirectionsCar} active={Boolean(value)}>
        <Autocomplete
          size={size || "small"}
          autoHighlight
          fullWidth
          openOnFocus
          readOnly={readOnly}
          options={data || []}
          loading={loading}
          value={value || null}
          onOpen={onOpen}
          onChange={(e, v) => {
            onChange(v);
          }}
          isOptionEqualToValue={(option, value) =>
            option?.placa === value?.placa
          }
          getOptionLabel={(option) => option?.name}
          renderOption={(props, option, { selected }) => {
            return (
              <MenuItem {...props} key={option?._id} selected={selected}>
                {/* <AvatarType icon={option?.icon} /> */}
                <Typography variant="inherit">{option?.name}</Typography>
                <Box flex={1} />
                <Typography color={"text.secondary"} variant="inherit">
                  {option?.placa}
                </Typography>
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="vehicle"
              margin={margin}
              multiline
              inputRef={inputRef}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              fullWidth
              variant={variant}
              placeholder={"Adicionar veículo"}
            />
          )}
        />
      </SelectorBox>
    );
  }
}

export default memo(VehiclesInput);
