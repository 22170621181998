import { Box, useScrollTrigger } from "@mui/material";
import React, { Suspense, lazy, memo, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useRouteContext from "../../../hooks/useRouteContext";
import "../../../main.css";
import rolesSettings from "../../../utils/rolesSettings";

import { useDispatch } from "react-redux";
import { closeElement } from "../../../store/features/base/modalsSlice";
import LoadingSection from "../../loading/LoadingSection";
import AdvancesSidebar from "./views/advances/AdvancesSidebar";
import ExpensesSidebarPessoal from "./views/expenses/pessoal/ExpensesSidebarPessoal";
import InsightsSidebar from "./views/insights/InsightsSidebar";
import ReportsSidebar from "./views/reports/ReportsSidebar";

const AdmSidebar = lazy(() => import("./views/adm/AdmSidebar"));

const SidebarContentWrapper = () => {
  const dispatch = useDispatch();
  const { routeId, role } = useRouteContext();

  const expandedSidebarContent = useSelector(
    (state) => state.settings.expandedSidebarContent
  );

  const [content, setContent] = useState(undefined);

  const scrolled = useScrollTrigger({
    target: content,
    disableHysteresis: true,
    threshold: 0,
  });

  const noHasSidebar = useMemo(
    () =>
      routeId === "home" ||
      ("hasSidebar" in rolesSettings[routeId]
        ? !rolesSettings[routeId]?.hasSidebar
        : !rolesSettings[routeId]?.profiles[role]?.hasSidebar),
    [routeId, role]
  );

  const isExpanded = useMemo(() => {
    if (noHasSidebar) {
      return false;
    } else {
      return expandedSidebarContent;
    }
  }, [expandedSidebarContent, noHasSidebar]);

  return (
    <Box
      width={isExpanded ? "13.75em" : noHasSidebar ? 0 : "4.8em"}
      height={"100%"}
      component={"div"}
      display={"flex"}
      flexDirection={"column"}
      onClick={(e) => dispatch(closeElement("modalExpenseView"))}
      sx={{
        overflowX: "hidden",
        boxShadow: scrolled
          ? "inset 0 10px 7px -11px rgba(0, 0, 0, 0.3)"
          : "none",
        borderTopRightRadius: 1,
        transition: "none",
      }}
      mr={-2}
    >
      <Box
        ref={(node) => node && setContent(node)}
        overflow={"scroll"}
        className="hover-scrollbar"
        flex={1}
        flexBasis={0}
        display={"flex"}
        flexDirection={"column"}
        sx={{ overflowX: "hidden" }}
        p={1}
        pr={1}
        pb={5}
      >
        <Suspense fallback={<LoadingSection size={20} />}>
          {routeId === "expenses" && (
            <ExpensesSidebarPessoal expanded={isExpanded} />
          )}
          {routeId === "reports" && <ReportsSidebar expanded={isExpanded} />}
          {routeId === "advances" && <AdvancesSidebar expanded={isExpanded} />}
          {routeId === "admin" && <AdmSidebar expanded={isExpanded} />}
          {routeId === "insights" && <InsightsSidebar />}
        </Suspense>
      </Box>
    </Box>
  );
};

export default memo(SidebarContentWrapper);
