import { Close, ImageOutlined } from "@mui/icons-material";
import { Alert, Box, IconButton, LinearProgress } from "@mui/material";
import React, { forwardRef, memo, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import DropzoneReceipt from "../../../../components/dropzone/dropzone-receipt/DropzoneReceipt";
import {
  selectEnableMultipleReceipts,
  selectRequiredReceipt,
} from "../../../../store/features/accountSlice";

const ReceiptsViewer = forwardRef(
  (
    {
      receipts = [],
      onUploadFiles = () => {},
      onRemove = () => {},
      loading,
      isEditable,
      isRoute,
      isNewExpense,
    },
    ref
  ) => {
    const enableMultipleReceipts = useSelector(selectEnableMultipleReceipts);
    const requiredReceipt = useSelector(selectRequiredReceipt);

    const [error, setError] = useState(null);

    useImperativeHandle(
      ref,
      () => {
        return {
          validate: () => {
            if (requiredReceipt && !receipts?.length && !isRoute) {
              setError(
                "É necessário inserir pelo menos um comprovante na despesa. Tente anexar um arquivo PDF ou imagem."
              );
              return false;
            }
            setError(null);
            return true;
          },
        };
      },
      [requiredReceipt, receipts, isRoute]
    );

    return (
      <Box
        flex={1}
        flexBasis={0}
        bgcolor={"elevation1.main"}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        p={receipts?.length ? 1 : 2}
        pr={receipts?.length ? 0 : 2}
        overflow={"scroll"}
        className="hover-scrollbar"
        mb={-2}
      >
        {loading ? (
          <Box
            flex={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            pb={10}
          >
            <ImageOutlined sx={{ fontSize: "5rem" }} color="disabled" />
            <LinearProgress sx={{ width: 100, mt: 2 }} />
          </Box>
        ) : (
          <>
            {Boolean(error) && !receipts?.length && (
              <Alert
                severity="error"
                sx={{ fontWeight: "500", boxShadow: 2 }}
                action={
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => setError(null)}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                }
              >
                {error}
              </Alert>
            )}
            <DropzoneReceipt
              enableMultipleReceipts={enableMultipleReceipts}
              files={receipts}
              onUploadFiles={onUploadFiles}
              onRemove={onRemove}
              isEditable={isEditable}
              isNewExpense={isNewExpense}
            />
          </>
        )}
      </Box>
    );
  }
);

export default memo(ReceiptsViewer);
