import { SendOutlined } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import React, { memo } from "react";

const ActionsFooter = ({
  onClose = () => {},
  onCreate = () => {},
  onSendToApproval = () => {},
  creating,
  sending,
  scanning,
  isOk,
}) => {
  return (
    <Stack
      px={2}
      minHeight={53}
      maxHeight={53}
      direction={"row"}
      alignItems={"center"}
      gap={1}
      borderTop={1}
      borderColor={"divider"}
    >
      <Button
        sx={{ px: 3 }}
        onClick={onCreate}
        variant="contained"
        disableElevation
        disabled={creating || scanning || !isOk}
      >
        {creating ? "Criando" : "Criar"}
      </Button>
      <Button onClick={onClose}>Cancelar</Button>
      <Box flex={1} />
      <Button
        endIcon={<SendOutlined />}
        variant="outlined"
        disableElevation
        disabled={sending || scanning || !isOk}
        onClick={onSendToApproval}
      >
        {sending ? "Enviando" : "Enviar"}
      </Button>
    </Stack>
  );
};

export default memo(ActionsFooter);
