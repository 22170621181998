import { AttachFileOutlined, UploadOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { memo, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import "../../../main.css";
import DropzoneBase from "../base";
import NoReceiptsLottie from "./components/NoReceiptsLottie";
import ReceiptsGrid from "./components/ReceiptsGrid";
import SingleReceiptView from "./components/SingleReceiptView";

function DropzoneReceipt({
  files = [],
  sx,
  containerStyle,
  onUploadFiles = async () => {},
  onRemove = () => {},
  isEditable,
  isNewExpense,
  enableMultipleReceipts,
  gridOptions = {
    cols: 2,
    gap: 8,
  },
}) {
  const [uploading, setUploading] = useState(false);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled: !isEditable,
    multiple: enableMultipleReceipts,
    maxFiles: enableMultipleReceipts ? undefined : 1,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/webp": [],
      "application/pdf": [],
    },
    onDrop: async (acceptedFiles) => {
      if (!enableMultipleReceipts && files?.length > 0) {
        return;
      }
      setUploading(true);
      await onUploadFiles(acceptedFiles);
      setUploading(false);
    },
  });

  const showUploadMore = useMemo(
    () => isEditable && (enableMultipleReceipts ? true : files?.length === 0),
    [isEditable, enableMultipleReceipts, files]
  );

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        ...containerStyle,
      }}
    >
      {files && files.length === 1 ? (
        <SingleReceiptView
          onClickRemove={(event) => onRemove(files[0]?.id)}
          file={files[0]}
          isEditable={isEditable}
        />
      ) : (
        <ReceiptsGrid
          receipts={files || []}
          isEditable={isEditable}
          onRemove={onRemove}
          gap={gridOptions.gap}
          cols={gridOptions.cols}
        />
      )}
      <DropzoneBase
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "sticky",
          bottom: 0,
          alignItems: "center",
          borderRadius: 3,
          flex: 1,
          border: 2,
          borderColor: "transparent",
        }}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        isDragAccept={isDragAccept}
        isDragActive={isDragActive}
        isDragReject={isDragReject}
      >
        {!Boolean(files?.length) && (
          <Box
            pt={4}
            display={"flex"}
            flexDirection="column"
            alignItems={"center"}
          >
            <input {...getInputProps()} />
            {isNewExpense ? (
              <NoReceiptsLottie />
            ) : (
              <Avatar
                sx={{
                  width: 120,
                  height: 120,
                  bgcolor: (t) => `${t.palette.primary.main}20`,
                  color: "primary.main",
                }}
              >
                <AttachFileOutlined
                  sx={{ fontSize: "5rem", transform: "rotate(45deg)" }}
                />
              </Avatar>
            )}
            <Typography
              textAlign={"center"}
              color={"text.secondary"}
              mt={2}
              fontSize="1.3rem"
            >
              {!isEditable
                ? "Nenhum comprovante adicionado"
                : isDragAccept
                ? "Solte para adicionar"
                : "Arraste os comprovantes aqui"}
            </Typography>
            {showUploadMore && (
              <Typography fontSize={".9rem"} color={"text.secondary"} my={1}>
                Ou
              </Typography>
            )}
          </Box>
        )}
        {showUploadMore && (
          <Button
            sx={{
              mb: 1,
              position: files?.length ? "absolute" : "sticky",
              bottom: 5,
              boxShadow: 2,
              height: 35,
              px: 1.5,
              transition: "none",
              color: "primary.main",
              zIndex: 100,
              bgcolor: "background.default",
              ":hover": { boxShadow: 6, bgcolor: "background.default" },
            }}
            disabled={uploading}
            disableElevation
            color="inherit"
            variant="contained"
          >
            {uploading ? (
              <CircularProgress
                color="inherit"
                size={20}
                thickness={6}
                sx={{ mr: 1 }}
              />
            ) : (
              <UploadOutlined sx={{ mr: 1 }} />
            )}
            {uploading ? "Carregando" : "Fazer upload"}
          </Button>
        )}
      </DropzoneBase>
    </Box>
  );
}

export default memo(DropzoneReceipt);
