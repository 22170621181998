import { ArrowDropDown, ArrowDropUp, Percent } from "@mui/icons-material";
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from "@mui/material";
import React, { memo } from "react";
import ApprovePartialWrapper from "../../../../actions/expenses/ApprovePartialWrapper";
import ApproveButton from "../../../../components/buttons/ApproveButton";

const ApproveToggleButton = ({
  expenseId,
  onClose = () => {},
  originalAmount = {},
  role,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        disableElevation
        variant="contained"
        disableRipple
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <ApproveButton ids={[expenseId]} onApprove={onClose} type="expenses" />
        <Button
          sx={{ px: 0.5 }}
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {open ? <ArrowDropUp /> : <ArrowDropDown />}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 10 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="top-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} timeout={0}>
            <Paper elevation={5}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <ApprovePartialWrapper
                    role={role}
                    onSuccess={() => {
                      setOpen(false);
                      onClose();
                    }}
                    renderComponent={({ openModal }) => (
                      <MenuItem
                        onClick={(e) => {
                          openModal({
                            target: e.target,
                            payload: {
                              originalAmount,
                              expenseId,
                            },
                          });
                        }}
                      >
                        <Percent sx={{ mr: 2 }} color="primary" />
                        Aprovar com desconto
                      </MenuItem>
                    )}
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default memo(ApproveToggleButton);
