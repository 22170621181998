import { CancelScheduleSendOutlined, Circle, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import CancelSendExpensesWrapper from "../../../../../actions/expenses/CancelSendExpensesWrapper";
import ExpenseStatusTag from "../../../../../components/form/ExpenseStatusTag";
import formatAmount from "../../../../../utils/formatAmount";
import { curr } from "../../../../../utils/more/currency_country";
import { formatMoment } from "../../../../../utils/more/date_functions";
import EditValuesModal from "./components/EditValuesModal";
import MoreButton from "./components/MoreButton";
import RulesIndicator from "./components/RulesIndicator";
import SapIntegration from "./components/SapIntegration";
import TransactionIndicator from "./components/TransactionIndicator";

const TimestampLabel = ({ children }) => {
  return (
    <>
      <Circle sx={{ fontSize: ".3rem" }} color="disabled" />
      <Typography
        color={"text.secondary"}
        fontSize={".9rem"}
        fontWeight={"500"}
      >
        {children}
      </Typography>
    </>
  );
};

const DialogHeader = ({
  onClose = () => {},
  loading,
  role,
  canCancelSend,
  isEditable,
  expenseId,
  originalValuesRef,
  disabled,
  onSendToApproval,
  status,
  category,
  sentAt,
  approvedAt,
  rejectedAt,
  currentStep,
  amount,
  currency,
  transactionStatus,
  transaction,
  hasPermissionToEdit,
  alerts = {},
  mainSeverity,
  isRoute,
  to,
  sapDocument,
  onRefresh = () => {},
}) => {
  return (
    <Box
      height={60}
      py={1}
      px={3}
      pl={1.8}
      display={"flex"}
      alignItems={"flex-start"}
    >
      <IconButton title="Fechar" onClick={onClose} color="inherit">
        <Close sx={{ fontSize: "25px" }} />
      </IconButton>
      <Box ml={0.5}>
        {loading ? (
          <Skeleton width={300} height={40} />
        ) : isRoute ? (
          <Typography
            maxWidth={500}
            noWrap
            fontSize={"1.1rem"}
            fontWeight={"600"}
          >
            Percurso para {to}
          </Typography>
        ) : (
          <Typography
            maxWidth={500}
            noWrap
            fontSize={"1.1rem"}
            fontWeight={"600"}
          >
            {category?.name ? `${category?.name} - ` : "Despesa de "}
            {curr(currency)} {formatAmount(amount)}
          </Typography>
        )}
        {loading ? (
          <Skeleton height={20} width={100} />
        ) : (
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <ExpenseStatusTag status={status} role={role} />
            {Boolean(currentStep) && status === "pending" && (
              <TimestampLabel>Etapa {currentStep}</TimestampLabel>
            )}
            {Boolean(sentAt) && status === "pending" && (
              <TimestampLabel>Enviada em {formatMoment(sentAt)}</TimestampLabel>
            )}
            {Boolean(approvedAt) && status === "approved" && (
              <TimestampLabel>Em {formatMoment(approvedAt)}</TimestampLabel>
            )}
            {Boolean(rejectedAt) && status === "rejected" && (
              <TimestampLabel>Em {formatMoment(rejectedAt)}</TimestampLabel>
            )}
          </Box>
        )}
      </Box>
      <Box flex={1} />
      <Box
        m={-1}
        display={"flex"}
        alignItems={"center"}
        pt={1}
        gap={loading ? 1.5 : 0.8}
      >
        {loading ? (
          <>
            <Skeleton variant="circular" width={30} height={30} />
            <Skeleton variant="circular" width={30} height={30} />
            <Skeleton variant="circular" width={30} height={30} />
          </>
        ) : (
          <>
            {role !== "personal" && hasPermissionToEdit && (
              <EditValuesModal
                originalValuesRef={originalValuesRef}
                disabled={disabled}
                expenseId={expenseId}
                isMatched={transactionStatus === 'isMatched'}
                isRoute={isRoute}
                role={role}
                onSave={onRefresh}
              />
            )}
            {role === "financial" && Boolean(sapDocument) && (
              <SapIntegration sapDocument={sapDocument} entityId={expenseId} />
            )}
            {Boolean(alerts?.rules?.length) && (
              <RulesIndicator
                rules={alerts?.rules || []}
                mainSeverity={mainSeverity}
              />
            )}
            {!isRoute && (
              <>
                <TransactionIndicator
                  transactionStatus={transactionStatus}
                  transaction={transaction}
                />
                <Divider sx={{ mx: 1, height: 25 }} orientation="vertical" />
              </>
            )}
            {canCancelSend && (
              <CancelSendExpensesWrapper
                role={role}
                onCancelSend={onRefresh}
                renderComponent={({ openModal }) => (
                  <Button
                    onClick={() => openModal([expenseId])}
                    color="primary"
                    disabled={disabled}
                    startIcon={<CancelScheduleSendOutlined />}
                  >
                    Cancelar envio
                  </Button>
                )}
              />
            )}
            <MoreButton
              expenseId={expenseId}
              disabled={disabled}
              originalValuesRef={originalValuesRef}
              onSendToApproval={onSendToApproval}
              isEditable={isEditable}
              role={role}
              onClose={onClose}
              onRefresh={onRefresh}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default memo(DialogHeader);
