import { Chip } from "@mui/material";
import React, { memo } from "react";

const IsNewChip = ({ label = "Novo" }) => {
  return (
    <Chip
      size="small"
      variant="outlined"
      color="success"
      component={"span"}
      sx={{
        border: "none",
        fontWeight: "700",
        fontSize: ".72rem",
        height: 19,
        mr: -1,
        bgcolor: (t) => `${t.palette.success.main}15`,
      }}
      label={label}
    />
  );
};

export default memo(IsNewChip);
