import { Box, useScrollTrigger } from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import useRouteContext from "../../hooks/useRouteContext";

const BoxContent = ({ children, sx }) => {
  const { routeId } = useRouteContext();
  const [content, setContent] = useState(undefined);

  const isFlat = useMemo(
    () => ["settings", "insights", "billing"].includes(routeId),
    [routeId]
  );

  const disableShadowOnScroll = useMemo(
    () => ["insights"].includes(routeId),
    [routeId]
  );

  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    target: content,
    threshold: 0,
  });

  useEffect(() => {
    if (isFlat && scrolled && !disableShadowOnScroll) {
      const appbarElement = document.getElementById("appbar_main");
      if (appbarElement) {
        appbarElement.style.boxShadow = "2px 2px 4px rgba(0, 0, 0, 0.15)";
      }
    } else {
      const appbarElement = document.getElementById("appbar_main");
      if (appbarElement) {
        appbarElement.style.boxShadow = "none";
      }
    }
  }, [scrolled]);

  return (
    <Box
      id="box_content"
      ref={(node) => isFlat && node && setContent(node)}
      component={"div"}
      onClick={(e) => e.stopPropagation()}
      borderRadius={isFlat ? 0 : 5}
      bgcolor={isFlat ? "transparent" : "background.default"}
      flex={1}
      flexBasis={0}
      width={"100%"}
      overflow={"auto"}
      display={"flex"}
      position={"relative"}
      flexDirection={"column"}
      mt={0}
      mb={isFlat ? -1.4 : 0}
      // boxShadow={isFlat ? 0 : "0px 0px 3px rgba(0,0,0,.05)"}
      sx={sx}
    >
      {children}
    </Box>
  );
};

export default memo(BoxContent);
