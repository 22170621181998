import {
  AttachMoney,
  Circle,
  PaymentOutlined,
  WalletOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Collapse,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Baseline from "../../../../components/form/Baseline";
import {
  getPayments,
  selectPaymentsByRole,
} from "../../../../store/features/configs/paymentsSlice";
import { CREDIT_CARDS_MODEL } from "../../../../utils/creditCards";
import formatAmount from "../../../../utils/formatAmount";
import { formatPaymentTypeText } from "../../../../utils/formaters";
import { curr } from "../../../../utils/more/currency_country";
import SelectorBox from "../SelectorBox";

export const getPaymentIcon = ({ type = "", cardType = "" }) => {
  return type === "credit-card" || type === "debit-card" ? (
    Boolean(cardType) ? (
      <img width={"30px"} src={CREDIT_CARDS_MODEL[cardType]?.image} />
    ) : (
      <PaymentOutlined color="primary" sx={{ fontSize: "1.3rem" }} />
    )
  ) : type === "advance" ? (
    <WalletOutlined color="primary" sx={{ fontSize: "1.3rem" }} />
  ) : type === "cash" ? (
    <AttachMoney color="primary" sx={{ fontSize: "1.3rem" }} />
  ) : (
    <PaymentOutlined color="action" sx={{ fontSize: "1.3rem" }} />
  );
};

const PaymentItem = memo(({ option, selected, isLoading, ...props }) => {
  return (
    <MenuItem {...props} selected={selected} sx={{ gap: 1, height: 60 }}>
      <Box width={30} fontSize={"1.3rem"}>
        {getPaymentIcon({ cardType: option?.card?.type, type: option?.type })}
      </Box>
      <Box flex={1} overflow={"hidden"}>
        <Typography noWrap variant="inherit" fontWeight={"600"}>
          {option?.name}
        </Typography>
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          {Boolean(option?.card) && (
            <>
              <Typography
                variant="body2"
                color={"text.secondary"}
                fontSize={".8rem"}
              >
                {option?.card?.number}
              </Typography>
              <Circle sx={{ fontSize: ".3rem" }} color="disabled" />
            </>
          )}
          <Typography
            variant="body2"
            color={"text.secondary"}
            fontSize={".8rem"}
            fontWeight={"500"}
            noWrap
          >
            {option?.type === "advance" ? (
              isLoading ? (
                <Skeleton height={15} width={120} />
              ) : (
                `${curr(option?.currency || "BRL")} ${formatAmount(
                  option?.balance || 0
                )} disponível`
              )
            ) : option?.isRefundable ? (
              "Reembolsável"
            ) : (
              "Não reembolsável"
            )}
          </Typography>
        </Box>
      </Box>
    </MenuItem>
  );
});

function PaymentInput({
  value,
  onChange = () => {},
  readOnly,
  variant = "filled",
  size,
  isEditable = true,
  role = "personal",
  disabled,
  endAdornment,
  margin = "dense",
  disableRefundable,
  controlledRefundable,
  refundable = false,
  required,
  error,
  helperText,
  clearError = () => {},
  placeholder = "Forma de pagamento",
}) {
  const dispatch = useDispatch();

  const {
    data,
    status,
    error: sliceError,
  } = useSelector((state) => selectPaymentsByRole(state, role));

  const loading = status === "loading";

  const onOpen = () => {
    dispatch(getPayments(role));
  };

  const formattedData = useMemo(
    () =>
      data?.map((payment) => ({
        ...payment,
        typeName: formatPaymentTypeText(payment?.type),
      })),
    [data]
  );

  if (!isEditable) {
    return (
      <>
        <Baseline
          Icon={(props) => (
            <Box {...props} width={20} pt={0.5}>
              {getPaymentIcon({
                cardType: value?.card?.type,
                type: value?.type,
              })}
            </Box>
          )}
          value={
            value?.name ? (
              <Box>
                <Typography variant="inherit">{value?.name}</Typography>
                <Typography
                  fontSize={".8rem"}
                  fontWeight={"500"}
                  color={"text.secondary"}
                >
                  {(controlledRefundable ? refundable : value?.isRefundable)
                    ? "Reembolsável"
                    : "Não reembolsável"}
                </Typography>
              </Box>
            ) : (
              ""
            )
          }
          emptyLabel={"Sem forma de pagamento"}
        />
      </>
    );
  } else {
    return (
      <SelectorBox
        Icon={(props) =>
          value ? (
            <Box {...props} width={20}>
              {getPaymentIcon({
                cardType: value?.card?.type,
                type: value?.type,
              })}
            </Box>
          ) : (
            <PaymentOutlined {...props} />
          )
        }
        iconSx={{ mt: disableRefundable ? 0 : 2 }}
        active={Boolean(value)}
        sx={{ alignItems: disableRefundable ? "center" : "flex-start" }}
      >
        <Autocomplete
          onOpen={onOpen}
          size={size || "small"}
          fullWidth
          disabled={disabled}
          autoHighlight
          readOnly={readOnly}
          options={formattedData || []}
          loading={loading}
          value={value || null}
          onChange={(e, v) => {
            clearError("payment");
            onChange(v || "");
          }}
          getOptionLabel={(option) => option?.name}
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          renderOption={(props, option, state) => {
            return (
              <PaymentItem
                {...props}
                key={option?._id}
                option={option}
                selected={state.selected}
                isLoading={loading}
              />
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin={margin}
              slotProps={{
                input: {
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={15} />
                      ) : null}
                      {!disabled && !readOnly && params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                },
              }}
              fullWidth
              required={required}
              error={error}
              variant={variant}
              multiline
              placeholder={placeholder}
              helperText={
                helperText ||
                (!disableRefundable && (
                  <Collapse timeout={100} in={Boolean(value)}>
                    <Typography
                      color={"primary.main"}
                      fontSize={".85rem"}
                      fontWeight={"500"}
                      component={"span"}
                    >
                      {(controlledRefundable ? refundable : value?.isRefundable)
                        ? "Reembolsável"
                        : "Não reembolsável"}
                    </Typography>
                  </Collapse>
                ))
              }
            />
          )}
        />
        {endAdornment && (
          <Box height={"78%"} display={"flex"} alignItems={"center"} pl={1}>
            {endAdornment}
          </Box>
        )}
      </SelectorBox>
    );
  }
}

export default memo(PaymentInput);
