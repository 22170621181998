import {
  ExpandLess,
  ExpandMore,
  NotesOutlined,
  Policy,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import BlinkingDot from "../../../../components/BlinkingDot";
import Baseline from "../../../../components/form/Baseline";
import UserListItem from "../../../../components/USERS/UserListItem";
import { getUsernameInitials } from "../../../../utils/formaters";
import { getProfilePicture } from "../../../../utils/functions/profile";

const RejectedInfo = ({ rejectedBy = {}, role }) => {
  const [expanded, setExpanded] = useState(role === "personal");

  return (
    <Box borderRadius={5} bgcolor={"elevation1.main"} mb={1.5}>
      <Stack
        borderRadius={5}
        direction={"row"}
        alignItems={"center"}
        onClick={() => setExpanded(!expanded)}
        sx={{
          cursor: "pointer",
          ":hover": {
            bgcolor: "action.hover",
          },
        }}
        p={2}
        py={1}
        gap={1}
      >
        <Typography
          fontSize={"1rem"}
          fontWeight={"600"}
          display={"flex"}
          alignItems={"flex-start"}
          component={"div"}
          gap={2}
        >
          <Box pt={1}>
            <BlinkingDot color="error" />
          </Box>
          Rejeitada por{" "}
          {!expanded &&
            (rejectedBy?.fromAuditor ? "Auditor" : rejectedBy?.user?.name)}
        </Typography>
        <Box flex={1} />
        {!expanded && (
          <Avatar
            key={rejectedBy?.user?._id}
            sx={{
              width: 20,
              height: 20,
              fontSize: ".7rem",
              fontWeight: 700,
              bgcolor: "#004a77",
              color: "#FFF",
            }}
            src={getProfilePicture(rejectedBy?.user?._id)}
          >
            {getUsernameInitials(rejectedBy?.user?.name)}
          </Avatar>
        )}
        <IconButton color="inherit" sx={{ m: -1 }} disableTouchRipple>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Stack>
      <Collapse unmountOnExit timeout={100} in={expanded}>
        <Stack p={2} pt={1} direction={"column"}>
          {rejectedBy?.fromAuditor ? (
            <Baseline
              sx={{ ml: -1, my: 2, alignItems: "flex-start" }}
              Icon={(p) => <Policy {...p} color="primary" />}
              value={"Auditor NotePay"}
            />
          ) : (
            <UserListItem
              sx={{ ml: -2 }}
              clickable={false}
              dense
              user={rejectedBy?.user || {}}
              imgHeight={30}
              imgWidth={30}
              imgSx={{
                bgcolor: "#004a77",
                color: "#FFF",
              }}
            />
          )}
          <Baseline
            sx={{ ml: -1, mt: 1, alignItems: "flex-start" }}
            Icon={NotesOutlined}
            value={rejectedBy?.comment}
            emptyLabel={"Nenhum motivo informado"}
            valueSx={{
              lineHeight: 1.25,
            }}
          />
        </Stack>
      </Collapse>
    </Box>
  );
};

export default memo(RejectedInfo);
