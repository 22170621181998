import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React from "react";

const ToggleRefundable = ({
  refundable,
  onChangeRefundable = () => {},
  editField,
  onChangeEditFiled = () => {},
}) => {
  return (
    <Box
      my={2}
      bgcolor={"elevation2.main"}
      p={2}
      py={0.5}
      pl={1}
      borderRadius={5}
    >
      <FormControlLabel
        control={
          <Checkbox
            sx={{ ml: 1 }}
            checked={editField}
            onClick={() => onChangeEditFiled(!editField)}
          />
        }
        label='Alterar campo "reembolsável" da despesa'
      />
      <Collapse timeout={100} unmountOnExit in={editField}>
        <FormControlLabel
          sx={{ mt: 1, mb: 1, ml: 3 }}
          control={
            <Switch
              checked={refundable}
              onClick={() => onChangeRefundable(!refundable)}
            />
          }
          label="Reembolsável"
        />
      </Collapse>
    </Box>
  );
};

export default ToggleRefundable;
