import { Close, CreditCardOff, CreditScore } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import DetailLabel from "../../../../../../components/form/DetailLabel";
import formatAmount from "../../../../../../utils/formatAmount";
import {
  formatDate,
  formatMoment,
} from "../../../../../../utils/more/date_functions";

const TransactionIndicator = ({ transactionStatus, transaction }) => {
  const [opened, setOpened] = useState(false);

  const isMatched = transactionStatus === "isMatched";

  return (
    <>
      <Tooltip
        title={
          isMatched
            ? "Transação correspondente"
            : "Nenhuma transação correspondente"
        }
      >
        <span>
          <IconButton
            id="transaction-info-icon-button"
            color={isMatched ? "success" : "default"}
            disabled={!isMatched}
            onClick={() => setOpened(true)}
          >
            {isMatched ? <CreditScore /> : <CreditCardOff />}
          </IconButton>
        </span>
      </Tooltip>

      {isMatched && (
        <Dialog
          open={opened}
          onClose={() => setOpened(false)}
          fullWidth
          transitionDuration={300}
          maxWidth="sm"
          slotProps={{
            transition: {
              unmountOnExit: true,
            },
          }}
        >
          <DialogTitle fontWeight={600}>
            <CreditScore color="success" sx={{ mr: 2 }} />
            Transação correspondente
            <Box flex={1} />
            <IconButton sx={{ m: -1 }} onClick={() => setOpened(false)}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ p: 3, pt: 2, pb: 5 }}>
            <DialogContentText>
              Alguns campos da despesa não estão mais disponíveis para edição.
            </DialogContentText>
            <Box mt={2} borderRadius={4} bgcolor={"#FFF"} p={2}>
              <Typography fontWeight={"500"} variant="h6" fontSize={"1.1rem"}>
                {transaction?.merchant}
              </Typography>
              <Stack mt={2} direction={"column"} gap={1}>
                <DetailLabel width={185} label="Valor">
                  {formatAmount(transaction?.amount)} {transaction?.currency}
                </DetailLabel>
                <DetailLabel width={185} label="Categoria">
                  {transaction?.category?.name}
                </DetailLabel>
                <DetailLabel width={185} label="Código MCC">
                  {transaction?.mcc} - {transaction?.mccText}
                </DetailLabel>
                <DetailLabel width={185} label="Forma de pagamento">
                  {transaction?.paymentMethod?.name}
                </DetailLabel>
                <DetailLabel width={185} label="Data">
                  {formatDate(transaction?.date)}
                </DetailLabel>
                <DetailLabel width={185} label="Estabelecimento">
                  {transaction?.merchant}
                </DetailLabel>
                <DetailLabel width={185} label="Local">
                  {transaction?.place?.name}
                </DetailLabel>
                <DetailLabel width={185} label="Criada em">
                  {formatMoment(transaction?.createdAt)}
                </DetailLabel>
              </Stack>
            </Box>
            <Typography m={1} fontSize={".7rem"} color="text.secondary">
              <strong>ID transação:</strong> {transaction?._id}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default memo(TransactionIndicator);
