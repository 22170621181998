import React, { memo, useEffect, useState } from "react";
import Lottie from "react-lottie";

const NoReceiptsLottie = () => {
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsPaused(true);
    }, 700);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Lottie
      height={200}
      options={{
        animationData: require("../../../../assets/lotties/envelope.json"),
        autoplay: true,
        loop: false,
      }}
      speed={2}
      isPaused={isPaused}
      isClickToPauseDisabled
    />
  );
};

export default memo(NoReceiptsLottie);
